var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BlockTitle", {
        staticClass: "msg-title",
        attrs: { title: "基本信息", "is-bold": true },
      }),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "班次名称", prop: "shiftName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入班次名称" },
                    model: {
                      value: _vm.form.shiftName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "shiftName", $$v)
                      },
                      expression: "form.shiftName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "班次颜色", prop: "colorThemeCode" } },
                [
                  _c("CbColorPicker", {
                    attrs: { color: _vm.form.fontRgba },
                    model: {
                      value: _vm.form.colorThemeCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "colorThemeCode", $$v)
                      },
                      expression: "form.colorThemeCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "班次简称", prop: "shiftShortName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入班次简称" },
                    model: {
                      value: _vm.form.shiftShortName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "shiftShortName", $$v)
                      },
                      expression: "form.shiftShortName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-row", [
        _c(
          "div",
          { staticClass: "type-card" },
          [
            _c(
              "a-form-model-item",
              { attrs: { label: "班次类型", prop: "shiftType" } },
              [
                _c(
                  "a-radio-group",
                  {
                    on: { change: _vm.change },
                    model: {
                      value: _vm.form.shiftType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "shiftType", $$v)
                      },
                      expression: "form.shiftType",
                    },
                  },
                  _vm._l(_vm.shiftTypeList, function (item) {
                    return _c(
                      "a-radio-button",
                      {
                        key: item.value,
                        staticClass: "radioBtn",
                        attrs: { value: item.value },
                      },
                      [
                        _c("div", { staticClass: "type-content" }, [
                          _c("p", [_vm._v(_vm._s(item.label))]),
                          _c("span", [_vm._v(_vm._s(item.text))]),
                        ]),
                        _vm.form.shiftType === item.value
                          ? _c("div", { staticClass: "after" }, [
                              _c("svg", { staticClass: "iconpark-icon" }, [
                                _c("use", { attrs: { href: "#check" } }),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }